import React from "react";
import IconMail from "../images/icons/theme/communication/send.svg";
import useInViewPort from "../hooks/useInViewPort";
import { Helmet } from "react-helmet";
//import { useContactFormMixpanelTracker } from "../hooks/useContactFormMixpanelTracker";

const ContactsBlock = ({ isInViewProp }) => {
  const [ref, isInView] = useInViewPort();

  //useContactFormMixpanelTracker(".section-contacts-block", [isInView, isInViewProp]);

  return (
    <section
      ref={ref}
      className="section-contacts section-contacts-block bg-primary-3 pb-0 position-relative"
    >
      <div className="container">
        <div className="row h-100">
          <div className="col-12 col-md-6 text-light h-100">
            <h2 className="display-4">Let's talk about your SaaS app!</h2>
            <div className="lead">
              <ul>
                <li className="pb-2">
                  Want to improve usability in your SaaS product?
                </li>
                <li className="pb-2">
                  Want to engage users at a higher level and outperform your
                  competition?
                </li>
              </ul>
            </div>
            <p className="lead">
              Our UX studio is here to help — every step of the way:
            </p>
            <ul className="list-unstyled mt-5">
              <li className="mb-5 d-flex">
                <IconMail className="icon bg-yellow icon-md" />
                <div className="ml-3 h3">
                  <a href="mailto:contact@adamfard.com">contact@adamfard.com</a>
                </div>
              </li>
              {/* <li className="mt-n5">
                <p className="lead">
                  Note: We do not accept unsolicited guest posts
                </p>
              </li> */}

              {/*<li className="mb-3 d-flex">
                <IconCall className="icon bg-yellow icon-md flip-x" />
                <div className="ml-3 h3">
                  <a href="tel:+4915735985861">+49 157 3598 5861</a>
                </div>
              </li>*/}

              {/* <li className="">
                <script type="text/javascript" src="https://widget.clutch.co/static/js/widget.js"></script>
                <div class="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="2" data-snippets="true" data-height="50" data-darkbg="1" data-clutchcompany-id="975724"></div>
              </li> */}
            </ul>
          </div>
          <div className="col-12 col-md-6 section-contacts__form">
            <div className="card card--common card-body">
              <div id="activecampaign-form">
                <div id="activecampaign-form__wrapper">
                  <div className=" _form_3" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {(isInView || isInViewProp) && (
        <Helmet>
          <script
            src="https://adamfard48700.activehosted.com/f/embed.php?id=3"
            async
          />
        </Helmet>
      )}
    </section>
  );
};

export default ContactsBlock;
